/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { Navigate, useLocation, useSearchParams } from "react-router-dom";
import { useEffect, useState, type ReactNode, type ElementType } from "react";

import { cssFns } from "@superweb/css";
import {
  Button,
  ComboBox,
  LinkButton,
  Snackbar,
  TextField,
  useComboBoxState,
  useTextFieldState,
  useTypo,
  useUiColors,
} from "@superweb/ui";
import { useLocale } from "@superweb/intl";
import { useQueryErrorResetBoundary } from "@superweb/api";

import { Message, useMessage } from "#internal/intl";
import {
  useCheckReferralLink,
  useCreateUserWithTeamLink,
} from "#internal/api/hiring-partners-app/users";
import {
  teamReferralCheckLinkParams,
  teamReferralCheckLink,
  teamReferralLinkParams,
  useMakeLink,
} from "#internal/routes";
import { getPassportUrl } from "#internal/utils/passport";
import { triggerLink } from "#internal/utils/triggerLink";
import { Logo } from "#internal/ui";
import { ErrorBoundary } from "#internal/ui/error-boundary";
import { HttpError } from "#internal/api/fetch";
import { isValidPhone } from "#internal/ui/phone-input";
import { useCommonSuggest } from "#internal/api/hiring-api/api";

const RedirectToPassport = ({
  mode,
  onLanguageChange,
}: {
  mode: "login" | "check";
  onLanguageChange?: (language?: string) => void;
}) => {
  const location = useLocation();
  const { referralId } = teamReferralLinkParams(location);
  const message = useMessage();
  const locale = useLocale();
  const [searchParams] = useSearchParams();
  const language = searchParams.get("locale") ?? locale.language;

  useEffect(() => {
    onLanguageChange?.(language);
  }, [language, onLanguageChange]);

  const redirectToPassport = () => {
    if (referralId && mode === "check") {
      const passportAuthUrl = getPassportUrl({
        lang: language,
        retpath: `${window.location.origin}${teamReferralCheckLink(referralId)}?language=${language}`,
      });

      triggerLink(passportAuthUrl, { target: "_self" });
    }

    if (referralId && mode === "login") {
      const passportAuthUrl = getPassportUrl({
        lang: language,
        retpath: window.location.href,
      });

      triggerLink(passportAuthUrl, { target: "_self" });
    }
  };

  if (!referralId) return null;

  return (
    <div
      css={{
        display: "grid",
        ...cssFns.placeContent("center"),
        rowGap: "32px",
        minHeight: "100vh",
      }}
    >
      <Logo showText />
      <Button
        view="action"
        onPress={() => {
          redirectToPassport();
        }}
        text={message({
          id: "c2976122-4899-427b-8bd5-87685d5264ef",
          context: "Referral page. Button text",
          default: "Log in",
        })}
      />
    </div>
  );
};

const RedirectFromTeamReferralLinkPage = ({
  onLanguageChange,
}: {
  onLanguageChange: (language: string | undefined) => void;
}) => {
  const message = useMessage();
  const location = useLocation();
  const { referralId = "" } = teamReferralLinkParams(location);
  const [searchParams] = useSearchParams();
  const makeLink = useMakeLink();

  const locale = useLocale();
  const language = searchParams.get("locale") ?? locale.language;

  useEffect(() => {
    onLanguageChange(language);
  }, [language, onLanguageChange]);

  const { data: { data } = {} } = useCheckReferralLink(referralId, {
    retry: false,
    enabled: Boolean(referralId),
    suspense: true,
  });

  const redirectToPassport = () => {
    if (referralId) {
      const passportAuthUrl = getPassportUrl({
        lang: language,
        retpath: `${window.location.origin}${teamReferralCheckLink(referralId)}?language=${language}`,
      });

      triggerLink(passportAuthUrl, { target: "_self" });
    }
  };

  if (data?.user_exists && data.user_details?.status !== "created")
    return <Navigate to="/" />;

  return (
    <div
      css={{
        display: "grid",
        ...cssFns.placeContent("center"),
        rowGap: "32px",
        minHeight: "100vh",
      }}
    >
      <Logo showText />
      <div css={{ display: "grid", rowGap: "16px" }}>
        <LinkButton
          view="action"
          link={makeLink(teamReferralCheckLink(referralId))}
          text={message({
            id: "224844bf-a9eb-4f27-babf-3a24b255b18b",
            context: "Referral link. Entry screen. Button",
            default: "Continue with current account",
          })}
        />
        <Button
          onPress={() => {
            redirectToPassport();
          }}
          text={message({
            id: "cab050c3-853c-4bda-9d7c-34d23b3e05c4",
            context: "Referral link. Entry screen. Button",
            default: "Choose another account",
          })}
        />
      </div>
    </div>
  );
};

const ReferralLinkCheckPage = ({
  onLanguageChange,
}: {
  onLanguageChange: (language: string | undefined) => void;
}) => {
  const location = useLocation();
  const { referralId = "" } = teamReferralCheckLinkParams(location);
  const [searchParams] = useSearchParams();

  const language = searchParams.get("language") ?? undefined;

  useEffect(() => {
    onLanguageChange(language);
  }, [language, onLanguageChange]);

  const { data: { data } = {}, refetch } = useCheckReferralLink(referralId, {
    retry: false,
    suspense: true,
  });

  if (data?.user_exists && data.user_details?.status !== "created")
    return <Navigate to="/" />;

  if (data?.user_exists === false)
    return <RegistrationFormPage onRegistration={refetch} />;

  if (data?.user_details?.status === "created")
    return <WaitingForActivationPage />;

  return <Navigate to="/" />;
};

export const ReferralLinkCheck = ({
  onLanguageChange,
}: {
  onLanguageChange: (language: string | undefined) => void;
}) => {
  return (
    <HttpErrors>
      <ReferralLinkCheckPage onLanguageChange={onLanguageChange} />
    </HttpErrors>
  );
};

export const RedirectFromTeamReferralLink = ({
  onLanguageChange,
}: {
  onLanguageChange: (language: string | undefined) => void;
}) => {
  return (
    <HttpErrors onLanguageChange={onLanguageChange}>
      <RedirectFromTeamReferralLinkPage onLanguageChange={onLanguageChange} />
    </HttpErrors>
  );
};

const ErrorScreen = ({
  title,
  description,
}: {
  title: string;
  description?: string;
}) => {
  const typo = useTypo();
  return (
    <div
      css={{
        display: "grid",
        alignContent: "center",
        justifyItems: "center",
        textAlign: "center",
        minHeight: "100vh",
        rowGap: "16px",
        ...cssFns.padding("16px"),
      }}
    >
      <span
        css={typo({
          level: "title4",
          weight: "regular",
          density: "normal",
        })}
      >
        {title}
      </span>
      {description && (
        <span
          css={typo({
            level: "body1",
            weight: "regular",
            density: "normal",
          })}
        >
          {description}
        </span>
      )}
    </div>
  );
};

const WaitingForActivationPage = () => {
  const typo = useTypo();
  const uiColors = useUiColors();
  const message = useMessage();

  const location = useLocation();
  const { referralId = "" } = teamReferralCheckLinkParams(location);
  const { data: { data, headers } = {} } = useCheckReferralLink(referralId, {
    retry: false,
    enabled: Boolean(referralId),
    suspense: true,
  });

  const username = data?.user_details?.username;

  const openExternalForm = () => {
    const url = `https://forms.yandex.ru/surveys/13473315.ed44b049a1a6652a3bb7951da050f1680c71fbd2/?username=${username}`;

    triggerLink(url, { target: "_blank" });
  };

  return (
    <div
      css={{
        display: "grid",
        alignContent: "center",
        justifyContent: "center",
        minHeight: "100vh",
      }}
    >
      <span
        css={typo({
          level: "title4",
          weight: "regular",
          density: "normal",
        })}
      >
        <Message
          id="8e1fa95e-eebf-4417-aba4-f1787c59fbce"
          context="Referral link. Error message"
          default="Waiting for activation"
        />
      </span>
      <div
        css={{
          color: uiColors.textMinor,
          ...typo({
            level: "body2",
            weight: "regular",
            density: "tight",
          }),
        }}
      >
        {[
          data?.user_details?.first_name,
          data?.user_details?.last_name,
          data?.user_details?.username,
        ]
          .filter(Boolean)
          .join(" ")}
      </div>
      <div
        css={{
          color: uiColors.textMinor,
          ...typo({
            level: "caption1",
            weight: "regular",
            density: "loose",
          }),
        }}
      >
        {headers?.requestId}
      </div>
      {username && (
        <div css={{ display: "grid", marginBlockStart: "15px" }}>
          <Button
            view="action"
            onPress={openExternalForm}
            text={message({
              id: "2904e2d1-50f9-4ca4-8931-ec9911de84eb",
              context: "Referral page. Link",
              default: "Complete registration",
            })}
          />
        </div>
      )}
    </div>
  );
};

export const HttpErrors = ({
  children,
  onLanguageChange,
  RedirectComponent = RedirectToPassport,
}: {
  children: ReactNode;
  onLanguageChange?: (language?: string) => void;
  RedirectComponent?: ElementType;
}) => {
  const { reset } = useQueryErrorResetBoundary();
  const message = useMessage();
  const [searchParams] = useSearchParams();
  const locale = useLocale();

  const language = searchParams.get("locale") ?? locale.language;

  useEffect(() => {
    onLanguageChange?.(language);
  }, [language, onLanguageChange]);

  return (
    <ErrorBoundary
      onReset={reset}
      fallbackRender={({ error }) => {
        if (error instanceof HttpError) {
          switch (error.status) {
            case 400: {
              return (
                <ErrorScreen
                  title={message({
                    id: "3a496dd5-7d79-4695-b185-fa997a45de88",
                    context: "Referral link. Error message",
                    default: "Invalid link",
                  })}
                  description={message({
                    id: "f0c6960e-7b94-4527-92e0-a302fc394b81",
                    context: "Referral link. Error description",
                    default:
                      "Contact the team leader to get a new referral link",
                  })}
                />
              );
            }
            case 401: {
              return (
                <RedirectComponent
                  mode="check"
                  onLanguageChange={onLanguageChange}
                />
              );
            }
            default:
              return (
                <ErrorScreen
                  title={`${error.status}:`}
                  description={error.stack}
                />
              );
          }
        }

        return null;
      }}
    >
      {children}
    </ErrorBoundary>
  );
};

type Values = {
  firstName: string;
  lastName: string;
  phone: string;
  country: string;
};

const useValidate = (
  isRequired?: (
    field: "firstName" | "lastName" | "phone" | "country",
  ) => boolean | undefined,
) => {
  const message = useMessage();
  const messageErrorRequired = message({
    id: "563374eb-dff2-45a9-b214-bbeab589f192",
    context: "Self-registration form. Error",
    default: "Required",
  });

  return (values: Values) => {
    const errors: Partial<Record<keyof Values, string | undefined>> = {};

    const rules: Record<string, () => void> = {
      firstName: () => {
        if (!values.firstName && isRequired?.("firstName")) {
          errors["firstName"] = messageErrorRequired;
        }
      },
      lastName: () => {
        if (!values.lastName && isRequired?.("lastName")) {
          errors["lastName"] = messageErrorRequired;
        }
      },
      phone: () => {
        if (!values.phone && isRequired?.("phone")) {
          errors["phone"] = messageErrorRequired;
        } else if (values.phone && !isValidPhone(values.phone)) {
          errors["phone"] = message({
            id: "cf718d5a-7590-4b35-a8a5-32ed8461675f",
            context: "Self-registration form. Error",
            default: "Invalid phone number",
          });
        }
      },
      country: () => {
        if (!values.country && isRequired?.("country")) {
          errors["country"] = messageErrorRequired;
        }
      },
    };

    Object.keys(values).forEach((key) => rules[key]?.());

    return errors;
  };
};

const RegistrationForm = ({
  onRegistration,
}: {
  onRegistration: () => void;
}) => {
  const [hasError, setHasError] = useState(false);
  const {
    mutate: createUser,
    isSuccess,
    isLoading,
  } = useCreateUserWithTeamLink({
    onSuccess: () => {
      onRegistration();
    },
    onError: () => {
      setHasError(true);
    },
  });
  const location = useLocation();
  const { referralId } = teamReferralCheckLinkParams(location);
  const countryList = useCountryData();

  const validate = useValidate((field) =>
    ["firstName", "lastName", "phone", "country"].includes(field),
  );
  const message = useMessage();
  const [firstNameState, setFirstNameState] = useTextFieldState({
    value: "",
  });
  const [lastNameState, setLastNameState] = useTextFieldState({
    value: "",
  });

  const [phoneState, setPhoneState] = useTextFieldState({
    value: "",
  });

  const [countryState, setCountryState] = useComboBoxState();

  const isSubmitButtonDisabled = Boolean(
    firstNameState.errorMessage ||
      lastNameState.errorMessage ||
      phoneState.errorMessage ||
      countryState.errorMessage ||
      isLoading,
  );

  const onSubmit = () => {
    const errors = validate({
      firstName: firstNameState.value,
      lastName: lastNameState.value,
      phone: phoneState.value,
      country: countryState.value?.key || "",
    });

    if (errors.firstName) {
      setFirstNameState((state) => ({
        ...state,
        errorMessage: errors.firstName,
        errorVisible: true,
      }));
    }

    if (errors.lastName) {
      setLastNameState((state) => ({
        ...state,
        errorMessage: errors.lastName,
        errorVisible: true,
      }));
    }

    if (errors.phone) {
      setPhoneState((state) => ({
        ...state,
        errorMessage: errors.phone,
        errorVisible: true,
      }));
    }

    if (errors.country) {
      setCountryState((state) => ({
        ...state,
        errorMessage: errors.country,
        errorVisible: true,
      }));
    }

    const hasErrors = Object.keys(errors).length !== 0;

    if (referralId && !hasErrors) {
      createUser({
        first_name: firstNameState.value,
        last_name: lastNameState.value,
        phone: phoneState.value,
        ref_id: referralId,
        country_code: countryState.value!.key,
      });
    }
  };

  const buttonText = message({
    id: "2e65e7e5-b3e0-4cc1-9131-42cd671b6c8d",
    context: "Self-registration form. Text field label",
    default: "Send",
  });

  if (isSuccess) {
    return <WaitingForActivationPage />;
  }

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onSubmit();
        }}
        css={{ display: "grid", rowGap: "16px", alignContent: "start" }}
      >
        <TextField
          label={message({
            id: "48621286-639d-4822-a0b4-b83a7a4ce176",
            context: "Self-registration form. Text field label",
            default: "First name",
          })}
          state={firstNameState}
          onChange={setFirstNameState}
        />
        <TextField
          label={message({
            id: "9a0c1fa6-b2eb-41cc-89b2-750b6e37d832",
            context: "Self-registration form. Text field label",
            default: "Last name",
          })}
          state={lastNameState}
          onChange={setLastNameState}
        />
        <TextField
          label={message({
            id: "f4477b92-4d1f-4064-b51a-827b46935ad7",
            context: "Self-registration form. Text field label",
            default: "Phone number",
          })}
          state={phoneState}
          placeholder="+"
          onChange={setPhoneState}
        />

        <ComboBox
          label={message({
            id: "90656830-953e-45dd-b02d-35978dd2bfed",
            context: "Self-registration form. Text field label",
            default: "Country",
          })}
          state={countryState}
          onChange={setCountryState}
          options={countryList.filter((country) =>
            country.label
              .toLowerCase()
              .includes(countryState.inputValue.toLowerCase()),
          )}
        />

        <Button
          size="l"
          view="action"
          text={buttonText}
          type="submit"
          disabled={isSubmitButtonDisabled}
        />
      </form>
      {hasError && (
        <Snackbar
          view="critical"
          text={message({
            id: "f0ce0ee2-deaa-4fcf-9c00-3fa83621a4b9",
            context: "Self-registration form. Snackbar. Error text",
            default: "Something went wrong",
          })}
          onClose={() => {
            setHasError(false);
          }}
        />
      )}
    </>
  );
};

const RegistrationFormPage = ({
  onRegistration,
}: {
  onRegistration: () => void;
}) => {
  const typo = useTypo();
  const uiColors = useUiColors();

  return (
    <div
      css={{
        display: "grid",
        ...cssFns.placeContent("center"),
        rowGap: "32px",
        minHeight: "100vh",
      }}
    >
      <div
        css={{
          backgroundColor: uiColors.background,
          ...cssFns.border({ radius: "16px" }),
          ...cssFns.padding("16px"),
        }}
      >
        <h1
          css={{
            ...typo({
              level: "title3",
              weight: "regular",
              density: "tight",
            }),
          }}
        >
          <Message
            id="41ab16fa-2549-4f4f-bcdc-f65b3ded90c6"
            context="Self-registration form. Title"
            default="Complete registration"
          />
        </h1>
        <RegistrationForm onRegistration={onRegistration} />
      </div>
    </div>
  );
};

const useCountryData = () => {
  const { data = [] } = useCommonSuggest("country_license");
  return data.map(({ key, value }) => ({
    key,
    label: value,
  }));
};
