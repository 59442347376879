/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { cssFns } from "@superweb/css";
import { useLocale } from "@superweb/intl";
import {
  Button,
  ComboBox,
  TextField,
  useComboBoxState,
  useTextFieldState,
  useTypo,
} from "@superweb/ui";

import { Message, useMessage } from "#internal/intl";
import { useV3CreateUserByTeamLink } from "#internal/api/hiring-partners-app/users";
import { useMetrika } from "#internal/metrika";
import { useCommonSuggest } from "#internal/api/hiring-api/api";
import { QualityControl } from "#internal/quality-control";

import { useFormContext } from "./form-provider";
import type { Screen } from "./form-page";

const useCountryData = () => {
  const { data = [] } = useCommonSuggest("country_license");
  return data.map(({ key, value }) => ({
    key,
    label: value,
  }));
};

const uploadFileGoal = "6a796b9c-d60b-49c6-b59e-bba158bbc5db";
const submitFormGoal = "8c518af4-ce4e-4cd7-bed8-45d9d277a8f3";

const formatTelegramValue = (value: string) => {
  let newValue = value.trim();
  if (newValue && newValue[0] !== "@") {
    newValue = `@${newValue}`;
  }
  return newValue;
};

const parseTelegramValue = (value: string) => {
  const newValue = value.trim();
  if (newValue[0] === "@") {
    return newValue.slice(1);
  }
  return newValue;
};

export const Form = ({
  onRegistration,
  screen,
  referralId,
  onNextStep,
  isCheckProfileLoading,
  setErrorMessage,
}: {
  onRegistration: () => void;
  screen: Screen;
  referralId: string;
  onNextStep: (phone: string) => void;
  isCheckProfileLoading?: boolean;
  setErrorMessage: (message: string) => void;
}) => {
  const { mutate: createUser, isLoading } = useV3CreateUserByTeamLink({
    onSuccess: () => {
      onRegistration();
    },
    onError: () => {
      setErrorMessage(
        message({
          id: "3fef3733-b11d-4740-aa42-6dfc9f362a29",
          context: "Self-registration form. Snackbar. Error text",
          default: "Registration form temporarily unavailable",
        }),
      );
    },
  });
  const typo = useTypo();
  const countryList = useCountryData();
  const { language } = useLocale();
  const { reachGoal } = useMetrika();

  const { formValues, setFormValues, isValid, staticErrors } = useFormContext();

  const message = useMessage();
  const [firstNameState, setFirstNameState] = useTextFieldState({
    value: formValues.firstName ?? "",
  });
  const [lastNameState, setLastNameState] = useTextFieldState({
    value: formValues.lastName ?? "",
  });
  const [phoneState, setPhoneState] = useTextFieldState({
    value: formValues.phone ?? "",
  });

  const [countryState, setCountryState] = useComboBoxState(
    formValues.country && formValues.countryName
      ? {
          value: {
            key: formValues.country,
            label: formValues.countryName,
          },
        }
      : undefined,
  );
  const [telegramState, setTelegramState] = useTextFieldState({
    value: formValues.telegram ?? "",
  });
  const [idNumberState, setIdNumberState] = useTextFieldState({
    value: formValues.idNumber ?? "",
  });

  const isNextButtonDisabled = Boolean(
    staticErrors.firstName ||
      staticErrors.lastName ||
      staticErrors.phone ||
      staticErrors.idNumber ||
      staticErrors.country ||
      staticErrors.telegram ||
      isLoading,
  );

  const onSubmit = () => {
    if (!isValid) return;
    const userData = {
      first_name: formValues.firstName!,
      last_name: formValues.lastName!,
      phone: formValues.phone!,
      country_code: formValues.country!,
      ref_id: referralId,
      identity_document: formValues.idNumber!,
      document_photo_id: formValues.photo!,
      user_photo_id: formValues.selfie!,
      locale: language,
    };
    if (formValues.telegram) {
      Object.assign(userData, { telegram_login: formValues.telegram });
    }
    reachGoal(submitFormGoal);
    createUser(userData);
  };

  if (screen !== "info") {
    return (
      <QualityControl
        countryCode={formValues.country ?? "rus"}
        uploadFileGoal={uploadFileGoal}
        onUpload={(value) =>
          setFormValues((formValues) => ({
            ...formValues,
            ...value,
          }))
        }
        isLoading={isLoading}
        isButtonDisabled={!isValid}
        onSubmit={onSubmit}
      />
    );
  }

  return (
    <form css={{ display: "grid" }}>
      <fieldset
        css={{
          ...cssFns.padding("0", "16px"),
          ...cssFns.border({ style: "none" }),
          ...cssFns.margin("0"),
          marginBlockEnd: "16px",
        }}
      >
        <legend
          css={{
            boxSizing: "border-box",
            ...typo({
              level: "body2",
              weight: "medium",
              density: "normal",
            }),
            minHeight: "56px",
            ...cssFns.padding("9px", "0"),
            display: "flex",
            alignItems: "center",
          }}
        >
          <Message
            id="50e1362a-1192-460e-8699-2067d3e4f465"
            context="Self-registration form. Fields' block title"
            default="Just a little more info"
          />
        </legend>
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            rowGap: "16px",
            alignContent: "start",
          }}
        >
          <TextField
            label={message({
              id: "8ba026d0-ef6e-49b8-b127-e7bca3e8a9e8",
              context: "Self-registration form. Text field label",
              default: "First name",
            })}
            state={firstNameState}
            onChange={(state) => {
              setFirstNameState({
                ...state,
                errorMessage: staticErrors.firstName,
              });
              setFormValues((formValues) => ({
                ...formValues,
                firstName: state.value,
              }));
            }}
          />
          <TextField
            label={message({
              id: "9144d7ca-e766-4dab-9fbf-49faae1ea228",
              context: "Self-registration form. Text field label",
              default: "Last name",
            })}
            state={lastNameState}
            onChange={(state) => {
              setLastNameState({
                ...state,
                errorMessage: staticErrors.lastName,
              });
              setFormValues((formValues) => ({
                ...formValues,
                lastName: state.value,
              }));
            }}
          />
          <TextField
            label={message({
              id: "5b3b6f99-589e-4e9a-a060-f988bc087b9e",
              context: "Self-registration form. Text field label",
              default: "Phone number",
            })}
            state={phoneState}
            placeholder="+"
            onChange={(state) => {
              const value = state.value.replace(/[^+\d]/g, "");
              setPhoneState({
                ...state,
                value,
                errorMessage: staticErrors.phone,
              });
              setFormValues((formValues) => ({
                ...formValues,
                phone: value,
              }));
            }}
          />
          <ComboBox
            label={message({
              id: "aa15b332-f2b4-4a87-ba90-f28089c44cb1",
              context: "Self-registration form. Text field label",
              default: "Country",
            })}
            state={countryState}
            onChange={(state) => {
              setCountryState({
                ...state,
                errorMessage: staticErrors.country,
              });
              setFormValues((formValues) => ({
                ...formValues,
                country: state.value?.key,
                countryName: state.value?.label,
              }));
            }}
            options={countryList.filter(
              (country) =>
                !["oth", "gui"].includes(country.key) &&
                country.label
                  .toLowerCase()
                  .includes(countryState.inputValue.toLowerCase()),
            )}
          />
          <TextField
            label={message({
              id: "d62484b5-74a7-49d6-bb46-5b4cf1d6ed0c",
              context: "Self-registration form. Text field label",
              default: "Telegram @",
            })}
            placeholder="@"
            state={telegramState}
            onChange={(state) => {
              setTelegramState({
                ...state,
                value: formatTelegramValue(state.value),
                errorMessage: staticErrors.telegram,
              });
              setFormValues((formValues) => ({
                ...formValues,
                telegram: parseTelegramValue(state.value),
              }));
            }}
          />
          <TextField
            label={message({
              id: "2a627a38-c11e-4ced-a831-ec7b57507043",
              context: "Self-registration form. Text field label",
              default: "ID document number",
            })}
            state={idNumberState}
            onChange={(state) => {
              setIdNumberState({
                ...state,
                errorMessage: staticErrors.idNumber,
              });
              setFormValues((formValues) => ({
                ...formValues,
                idNumber: state.value,
              }));
            }}
          />
        </div>
      </fieldset>

      <div
        css={{
          marginBlockStart: "auto",
          display: "grid",
          ...cssFns.padding("8px"),
        }}
      >
        <Button
          size="l"
          view="action"
          text={message({
            id: "6debacdc-9594-4e1b-bd2d-c85c6c63742f",
            context: "Self-registration form. Button label",
            default: "Next",
          })}
          type="button"
          disabled={isNextButtonDisabled}
          onPress={() => onNextStep(formValues.phone ?? "")}
          progress={isCheckProfileLoading}
        />
      </div>
    </form>
  );
};
