export const toCookieObject = (): {
  yandex_login?: string;
  [key: string]: string | undefined;
} => {
  const cookie = document.cookie;

  if (cookie.length < 1) {
    return {};
  }

  const cookieEntries = cookie
    .split("; ")
    .map((entryString) => entryString.split("="));

  return Object.fromEntries(cookieEntries) as {
    yandex_login?: string;
    [key: string]: string | undefined;
  };
};
