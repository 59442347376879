import { useEffect, useState } from "react";

import { useQueryClient, type UseQueryOptions } from "@superweb/api";

import {
  useV1FeedNotificationMarkRead,
  useV1FeedNotificationMarkReadAll,
  useV1FeedsSummaryPost,
  v1FeedsQueryKeys,
  type UseV1FeedsSummaryPostResponseOk,
} from "#internal/api/hiring-partners-app/feeds";

export const useFeedsSummary = (
  options?: UseQueryOptions<UseV1FeedsSummaryPostResponseOk>,
) => {
  const [refetchInterval, setRefetchInterval] = useState<number>();
  const query = useV1FeedsSummaryPost({
    refetchInterval,
    ...options,
  });
  const { data } = query;

  useEffect(() => {
    const pollingTime = data?.polling_delay
      ? data.polling_delay * 1000
      : undefined;
    setRefetchInterval(pollingTime);
  }, [data?.polling_delay]);

  return query;
};

export const useFeedNotificationMarkRead = () => {
  const queryClient = useQueryClient();
  return useV1FeedNotificationMarkRead({
    onSuccess: () => {
      void queryClient.invalidateQueries(v1FeedsQueryKeys.summaryPost());
      void queryClient.invalidateQueries(
        v1FeedsQueryKeys.notificationListPost(),
      );
    },
  });
};

export const useFeedNotificationMarkReadAll = () => {
  const queryClient = useQueryClient();
  return useV1FeedNotificationMarkReadAll({
    onSuccess: () => {
      void queryClient.invalidateQueries(v1FeedsQueryKeys.summaryPost());
      void queryClient.invalidateQueries(
        v1FeedsQueryKeys.notificationListPost(),
      );
    },
  });
};
