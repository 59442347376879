// https://a.yandex-team.ru/arcadia/taxi/backend-py3/services/hiring-partners-app/docs/yaml/api/users.yaml
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
  type UseInfiniteQueryOptions,
  type UseMutationOptions,
  type UseQueryOptions,
} from "@superweb/api";
import { useLocale } from "@superweb/intl";

import { fetch } from "#internal/api/fetch";

import type * as api from "../generated/hiring-partners-app";

import type {
  YandexLoginPersonalId,
  City,
  YandexLogin,
  Username,
  Team,
  ParameterReferralId,
  Phone,
  Vacancy,
} from "./definitions";

/** User profile status*/
type UserStatus = "created" | "active" | "deactivated" | "rejected";

type ResponseV1UsersMe = UserDoc;

export type UseMeResult = {
  headers: { requestId: string };
  data: ResponseV1UsersMe;
};

const createUsersMeUrl = () => {
  const searchParams = new URLSearchParams({
    current_app: "v1",
  });
  return `/api/v1/users/me?${searchParams.toString()}`;
};

export const useMe = (options?: UseQueryOptions<UseMeResult>) => {
  const { baseName } = useLocale();
  const url = createUsersMeUrl();

  return useQuery(
    [url],
    () => {
      return fetch(
        new Request(url, {
          credentials: "include",
          headers: {
            "Accept-Language": baseName,
          },
        }),
        {
          200: async (r) => {
            const headers = { requestId: r.headers.get("x-yarequestid")! };
            const data = (await r.json()) as ResponseV1UsersMe;

            return { headers, data };
          },
        },
      );
    },
    options,
  );
};

type RequestV1UsersAccountSelfUpdate = { locale?: string };
type ResponseV1UsersOperationSuccess = { yandex_login: YandexLogin };

export const useChangeUserSettings = (
  options?: UseMutationOptions<
    ResponseV1UsersOperationSuccess,
    unknown,
    RequestV1UsersAccountSelfUpdate
  >,
) => {
  const url = "/api/v1/users/account/self-update";

  return useMutation(
    (settingsData: RequestV1UsersAccountSelfUpdate) =>
      fetch(
        new Request(url, {
          method: "POST",
          credentials: "include",
          body: JSON.stringify(settingsData),
        }),
        {
          200: (res) => res.json() as Promise<ResponseV1UsersOperationSuccess>,
          202: (res) => res.json() as Promise<ResponseV1UsersOperationSuccess>,
        },
      ),
    options,
  );
};

type ResponseV1UsersLink = {
  link: string;
};

export const useInviteLink = (
  options?: UseQueryOptions<ResponseV1UsersLink>,
) => {
  const url = "/api/v1/users/link";

  return useQuery(
    [url],
    () => {
      return fetch(
        new Request(url, {
          credentials: "include",
          method: "POST",
        }),
        {
          200: (res) => res.json() as Promise<ResponseV1UsersLink>,
        },
      );
    },
    options,
  );
};

export type ExternalUserProfile = {
  personal_yandex_login_id: string;
  first_name: string;
  last_name: string;
  middle_name: string;
  status: string;
};

type ResponseExternalV1UsersList = {
  users: Array<ExternalUserProfile>;
  is_bound_by_limit: boolean;
};

export type RequestPostV1UsersDetails = {
  personal_yandex_login_id: string;
};

export type ResponseV1UsersDetails = {
  yandex_login: string;
  phone?: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  status: string;
  team_id?: number;
  meta_role?: string;
};

export type UserInfo = {
  personal_yandex_login_id: string;
  name: string;
  status: string;
};

export type UsersListPostRequestType = {
  status?: string;
};

export const useUsersList = (
  params?: UsersListPostRequestType,
  options?: Omit<
    UseInfiniteQueryOptions<ResponseExternalV1UsersList>,
    "getNextPageParam"
  >,
) => {
  const url = "/api/external/v1/users/list";

  return useInfiniteQuery(
    [url, params],
    ({ pageParam = 0 }) => {
      const request = {
        ...params,
        limit: 30,
        offset: pageParam * 30,
      };

      return fetch(
        new Request(url, {
          method: "POST",
          body: JSON.stringify(request),
          credentials: "include",
        }),
        { 200: (res) => res.json() as Promise<ResponseExternalV1UsersList> },
      );
    },
    {
      ...options,
      getNextPageParam: (lastPage, allPages) =>
        lastPage.is_bound_by_limit && allPages.length,
    },
  );
};

export const useUserDetails = (
  params: RequestPostV1UsersDetails,
  options?: UseQueryOptions<ResponseV1UsersDetails>,
) => {
  const url = "/api/v1/users/details";

  return useQuery(
    [url, params.personal_yandex_login_id],
    () =>
      fetch(
        new Request(url, {
          body: JSON.stringify(params),
          credentials: "include",
          method: "POST",
        }),
        {
          200: (res) => res.json() as Promise<ResponseV1UsersDetails>,
        },
      ),
    options,
  );
};

export type RequestExternalV1UsersAccountCreate = {
  yandex_login: string;
  username: string;
  first_name?: string;
  last_name?: string;
  middle_name?: string;
  meta_role?: string;
  phone?: string;
};

export type RequestExternalV1UsersAccountUpdate = {
  yandex_login: string;
  first_name?: string;
  last_name?: string;
  middle_name?: string;
  meta_role?: string;
  phone?: string;
  team_id?: number;
  status?: string;
};

// options?: UseMutationOptions<
// ResponseV1UsersOperationSuccess,
// unknown,
// RequestV1UsersAccountSelfUpdate
// >

export const useCreateUser = (
  options?: UseMutationOptions<
    ResponseV1UsersOperationSuccess,
    unknown,
    RequestExternalV1UsersAccountCreate
  >,
) => {
  const url = "/api/external/v1/users/account/create";

  return useMutation((params: RequestExternalV1UsersAccountCreate) => {
    return fetch(
      new Request(url, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(params),
      }),
      {
        200: (res) => res.json() as Promise<ResponseV1UsersOperationSuccess>,
      },
    );
  }, options);
};

export const useUpdateUser = (
  options?: UseMutationOptions<
    ResponseV1UsersOperationSuccess,
    unknown,
    RequestExternalV1UsersAccountUpdate
  >,
) => {
  const url = "/api/external/v1/users/account/update";

  return useMutation((params: RequestExternalV1UsersAccountUpdate) => {
    return fetch(
      new Request(url, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(params),
      }),
      {
        200: (res) => res.json(),
        202: (res) => res.json(),
      },
    );
  }, options);
};

export type RefLinkFlow = "user" | "eats_curator";

type UseExternalV2UsersCreateLinkPostParams = {
  park_id?: AccountParkId;
  flow?: RefLinkFlow;
};

type UseExternalV2UsersCreateLinkPostResult = {
  link: string;
  park_id?: string;
  flow?: string;
};

type UseExternalV2UsersCreateLinkPostOptions =
  UseQueryOptions<UseExternalV2UsersCreateLinkPostResult>;

export const useExternalV2UsersCreateLinkPost = (
  params: UseExternalV2UsersCreateLinkPostParams,
  options?: UseExternalV2UsersCreateLinkPostOptions,
) => {
  const url = "/api/external/v2/users/create-link";

  return useQuery(
    [url, params],
    () =>
      fetch(
        new Request(url, {
          credentials: "include",
          method: "POST",
          body: JSON.stringify(params),
        }),
        {
          200: (res) =>
            res.json() as Promise<UseExternalV2UsersCreateLinkPostResult>,
        },
      ),
    options,
  );
};

/** A driver id of user in park, where he was created like driver. Common thing for "scouts" */
type AccountDriverId = string;

/** Id of park where user was created like driver. Commonthing for "scouts". */
type AccountParkId = string;

/** User's meta role. */
type MetaRole = string;

/** User's meta role. */
type MetaRoleTranslation = string;

type MiddleName = string;
/** Список флагов для юзера из экспериментов */
type PermissionFlags = string[];

/**User's role.*/
type Role = string;

type TeamId = number;

type OnboardingInfo = {
  need_to_show: boolean;
  onboarding_flow: "" | "eats_ru";
};

/** User profile doc */
type UserDoc = {
  account_driver_id?: AccountDriverId;
  account_park_id?: AccountParkId;
  cities?: City[];
  first_name?: FirstName;
  last_name?: LastName;
  locale?: Locale;
  username: Username;
  /** @deprecated Это поле планируется убирать из ответа этой ручки, не используйте его */
  meta_role?: MetaRole;
  meta_role_translation?: MetaRoleTranslation;
  middle_name?: MiddleName;
  permission_flags?: PermissionFlags;
  personal_yandex_login_id: YandexLoginPersonalId;
  role?: Role;
  status?: UserStatus;
  team_id?: TeamId;
  vacancies?: Vacancy[];
  country_code?: string;
  onboarding_info?: OnboardingInfo;
};

/** Request validates invite link and gathers user existence data*/
type ResponseV2UserByInviteCode = {
  /** Indicates whether user exists with given login & uid*/
  user_exists: boolean;
  user_details?: UserDoc;
};

export type UseCheckRereffalLinkResult = {
  headers: { requestId: string };
  data: ResponseV2UserByInviteCode;
};

export const useCheckReferralLink = (
  refId: ParameterReferralId,
  options?: UseQueryOptions<UseCheckRereffalLinkResult>,
) => {
  const url = `/api/external/v1/users/invited/${refId}`;

  return useQuery(
    [url],
    () =>
      fetch(
        new Request(url, {
          credentials: "include",
        }),
        {
          200: async (r) => {
            const headers = { requestId: r.headers.get("x-yarequestid")! };
            const data = (await r.json()) as ResponseV2UserByInviteCode;

            return { headers, data };
          },
        },
      ),
    options,
  );
};

/**Referral ID of user*/
type RefId = string;

/**Код языка локализации*/
type Locale = string;

type FirstName = string;

type LastName = string;

type RequestCreateUserByTeamLink = {
  ref_id: RefId;
  locale?: Locale;
  first_name: FirstName;
  last_name: LastName;
  phone: Phone;
  country_code: string;
};

/** User created by team lead referral link */
type ResponseUserCreatedByTeamlink = {
  username?: Username;
  team?: Team;
};

export const useCreateUserWithTeamLink = (
  options?: UseMutationOptions<
    ResponseUserCreatedByTeamlink,
    unknown,
    RequestCreateUserByTeamLink
  >,
) => {
  const url = "/api/external/v2/users/create";

  return useMutation(
    (userData: RequestCreateUserByTeamLink) =>
      fetch(
        new Request(url, {
          method: "POST",
          credentials: "include",
          body: JSON.stringify(userData),
        }),
        {
          201: (res) => res.json() as Promise<ResponseUserCreatedByTeamlink>,
        },
      ),
    options,
  );
};

type RequestPostExternalV1UsersContacts = {
  username: Username;
};
type ResponseExternalV1UsersContacts = {
  telegram?: string;
  phone?: string;
};

export const useUserContacts = (
  params: RequestPostExternalV1UsersContacts,
  options?: UseQueryOptions<ResponseExternalV1UsersContacts>,
) => {
  const url = "/api/external/v1/users/contacts";

  return useQuery(
    [url, params.username],
    () =>
      fetch(
        new Request(url, {
          body: JSON.stringify(params),
          credentials: "include",
          method: "POST",
        }),
        {
          200: (res) => res.json() as Promise<ResponseExternalV1UsersContacts>,
        },
      ),
    options,
  );
};

type IdentityDocument = {
  identity_document: string;
};

type TelegramLogin = {
  telegram_login?: string;
};

type UserDocuments = {
  document_photo_id: string;
  user_photo_id: string;
};

type RequestV3CreateUserByTeamLink = RequestCreateUserByTeamLink &
  IdentityDocument &
  TelegramLogin &
  UserDocuments;

export const useV3CreateUserByTeamLink = (
  options?: UseMutationOptions<UserDoc, unknown, RequestV3CreateUserByTeamLink>,
) => {
  const url = "/api/external/v3/users/create";

  return useMutation(
    (userData: RequestV3CreateUserByTeamLink) =>
      fetch(
        new Request(url, {
          method: "POST",
          credentials: "include",
          body: JSON.stringify(userData),
        }),
        {
          201: (res) => res.json() as Promise<UserDoc>,
        },
      ),
    options,
  );
};

type UseV1UsersPlatformProfileCanCreatePostRequest =
  api.paths["/external/v1/users/platform-profile/can-create"]["POST"]["request"];
type UseV1UsersPlatformProfileCanCreatePostResponse =
  api.paths["/external/v1/users/platform-profile/can-create"]["POST"]["response"];

export type UseV1UsersPlatformProfileCanCreatePostResult =
  | { ok: true }
  | {
      ok: false;
      error: Extract<
        UseV1UsersPlatformProfileCanCreatePostResponse,
        { status: 400 }
      >["body"];
      trace_id: string;
    };

export type UseV1UsersPlatformProfileCanCreatePostParams = Omit<
  UseV1UsersPlatformProfileCanCreatePostRequest,
  "url" | "method"
>;

export type UseV1UsersPlatformProfileCanCreatePostOptions = UseMutationOptions<
  UseV1UsersPlatformProfileCanCreatePostResult,
  unknown,
  UseV1UsersPlatformProfileCanCreatePostParams
>;

export const useV1UsersPlatformProfileCanCreatePost = (
  options?: UseV1UsersPlatformProfileCanCreatePostOptions,
) =>
  useMutation(
    ({ headers, body }) =>
      fetch(
        new Request("/api/external/v1/users/platform-profile/can-create", {
          method: "POST",
          headers,
          body: JSON.stringify(body),
        }),
        {
          200: async () => ({ ok: true as const }),
          400: async (r) => ({
            ok: false as const,
            error: (await r.json()) as Extract<
              UseV1UsersPlatformProfileCanCreatePostResponse,
              { status: 400 }
            >["body"],
            trace_id: r.headers.get("x-yatraceid") ?? "",
          }),
        },
      ),
    options,
  );

type UseV3UsersCreateRetryPostRequest =
  api.paths["/external/v3/users/create/retry"]["POST"]["request"];
type UseV3UsersCreateRetryPostResponse =
  api.paths["/external/v3/users/create/retry"]["POST"]["response"];

export type UseV3UsersCreateRetryPostResult =
  | {
      ok: true;
      data: Extract<UseV3UsersCreateRetryPostResponse, { status: 200 }>["body"];
    }
  | {
      ok: false;
      error: Extract<
        UseV3UsersCreateRetryPostResponse,
        { status: 400 }
      >["body"];
    };

export type UseV3UsersCreateRetryPostParams = Omit<
  UseV3UsersCreateRetryPostRequest,
  "url" | "method"
>;

export type UseV3UsersCreateRetryPostOptions = UseMutationOptions<
  UseV3UsersCreateRetryPostResult,
  unknown,
  UseV3UsersCreateRetryPostParams
>;

export const useV3UsersCreateRetryPost = (
  options?: UseV3UsersCreateRetryPostOptions,
) =>
  useMutation(
    ({ headers, body }) =>
      fetch(
        new Request("/api/external/v3/users/create/retry", {
          method: "POST",
          headers,
          body: JSON.stringify(body),
        }),
        {
          200: async (r) => ({
            ok: true as const,
            data: (await r.json()) as Extract<
              UseV3UsersCreateRetryPostResponse,
              { status: 200 }
            >["body"],
          }),
          400: async (r) => ({
            ok: false as const,
            error: (await r.json()) as Extract<
              UseV3UsersCreateRetryPostResponse,
              { status: 400 }
            >["body"],
          }),
        },
      ),
    options,
  );

export const useMarkOnboardingCompleted = () => {
  const queryClient = useQueryClient();
  const url = "api/external/v1/users/onboarding/mark-completed";

  return useMutation(
    () =>
      fetch(
        new Request(url, {
          method: "POST",
          credentials: "include",
        }),
        {
          204: async (res) => res.ok,
        },
      ),
    {
      onSuccess: () => {
        void queryClient.invalidateQueries([createUsersMeUrl()]);
      },
    },
  );
};
