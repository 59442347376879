/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import type { ReactNode } from "react";

import { Flags } from "@superweb/flags";

import { useMe } from "./api/hiring-partners-app/users";

type Permission =
  | "can_show_eats_tariffs"
  | "show_invite_link"
  | "skip_birthday"
  | "skip_middle_name"
  | "skip_driver_license_country_of_issue"
  | "skip_driver_license_expiry_date"
  | "skip_sts"
  | "skip_vin"
  | "view_eats_leads"
  | "view_agents"
  | "view_create_lead"
  | "can_show_eats_lead_check"
  | "users_show_page"
  | "can_create_users"
  | "redirect_to_ref_from_add_user_form"
  | "show_park_selector"
  | "show_flow_selector"
  | "hide_middle_name"
  | "hide_driver_license_expiry_date"
  | "hide_vin"
  | "hide_sts"
  | "can_show_eats_info"
  | "select_park_for_natural_person"
  | "use_server_side_car_brand_search"
  | "view_billing_page"
  | "show_version_snack_message"
  | "enable_create_lead_activity_selfemployment"
  | "enable_create_lead_activity_driver"
  | "can_edit_users"
  | "can_edit_users_team"
  | "skip_lead_check_step"
  | "view_activation_list"
  | "view_generate_ref_link_page"
  | "view_lead_complaints"
  | "view_tariff_in_settings"
  | "use_create_yango_driver_new_ui"
  | "view_gender_field"
  | "enable_create_lead_activity_lead"
  | "view_vacancy_choose_page"
  | "view_teamlead_dashboard_page"
  | "view_download_csv_at_new_design"
  | "view_finances_page"
  | "show_balance_at_dashboard"
  | "can_go_to_finances_page_from_balance_at_dashboard"
  | "show_target_events_block_at_lead_page"
  | "enable_self_employment_usage"
  | "view_scout_page"
  | "can_go_to_scout_page_from_teamlead_dashboard"
  | "can_change_park_for_ref_link"
  | "display_eats_support_button"
  | "view_new_driver_check_form"
  | "use_backend_detailed_lead"
  | "show_notification_center";

export const FlagsProvider = ({ children }: { children: ReactNode }) => {
  const {
    data: { permission_flags },
  } = useMe({ suspense: true, retry: false }).data!;

  const permissions = new Set(permission_flags ?? []) as Set<Permission>;
  const can = (permission: Permission) => permissions.has(permission);
  const flags = {
    "524b51f2-41cb-48f9-b20b-a012fcf775a9": can("view_agents"),
    "baad11a3-cd13-4b0a-a6e3-6222eda2ec5b": can("view_create_lead"),
    "1a4650da-671e-48ac-8bb9-4b2d88ffac4a": can("can_edit_users"),
    "e2dc4e89-2a13-470c-ba85-09edcf6e4d93": can("can_edit_users_team"),
    "76208055-7c2a-4c3a-9571-f22dc8d221d3": can("display_eats_support_button"),
    "fb088a44-a66f-44b6-bc73-dde475819c69": can("view_eats_leads"),
    "2fecfb72-491d-4bfc-9528-2f2ed2f42728": can("view_eats_leads"),
    "7c258246-8d5f-443c-ba78-1bc02282ad19": can("view_eats_leads"),
    "03ffb010-bc28-4dbc-9689-ec79ad16739c": can("can_show_eats_tariffs"),
    "e2ed57c3-e6b2-4ea0-9315-310d9f17b216": can("can_show_eats_tariffs"),
    "8eceb50f-e5ac-438f-a4f2-f69b1aa9298a": can("users_show_page"),
    "aceabd4c-3356-4e10-97a3-50f029388e75": can("users_show_page"),
    "7bee9ef1-1eeb-42ad-b756-3f0627108225": can("can_show_eats_info"),
    "12059bf3-4b3f-434d-87c0-ef1361a356be": can("can_show_eats_info"),
    "6ecaf922-49aa-4375-99e8-1da9f4372be5": can("can_show_eats_info"),
    "b0b387fd-e025-47a7-b02b-320ae3420080": can("view_billing_page"),
    "0a0522e0-a497-4d9b-8140-ad31124f3d03": can("view_billing_page"),
    "ef9dac6a-cbad-4d14-bef8-c12871cb9a84": can("hide_middle_name"),
    "1f737ad9-61fa-4400-8d54-278872a9e4c3": can("view_tariff_in_settings"),
    "5164bc69-07ea-4372-a22a-4a48960ba6b2": can(
      "hide_driver_license_expiry_date",
    ),
    "571cb0a0-fbfc-4c6a-8f3f-1fc6e3a63c99": can("hide_vin"),
    "c4e37e28-e62f-48d3-9b45-2856cef243c5": can("hide_sts"),
    "6578b137-40da-452e-977f-d22fe2fc5102": can("show_version_snack_message"),
    "612275e5-2160-4063-bdd1-23c519c9e92c": can(
      "use_server_side_car_brand_search",
    ),
    "f2d4fdd0-aeaa-4d82-a4a2-16e7e3aaed9f": can(
      "use_server_side_car_brand_search",
    ),
    "9ff7e046-5a71-4225-b9f0-62b02653464f": !can("skip_sts"),
    "e3c666f1-7c63-4ade-9ea5-d1699adf3f81": can("skip_sts"),
    "f11aa6a0-3e9f-4b1d-ad56-03f4ea57affe": !can("skip_vin"),
    "e1d94d85-7071-404e-b095-1a39968be48a": can("skip_vin"),
    "f1a67dba-b640-48b6-8d24-ec60a6066007": can(
      "enable_create_lead_activity_driver",
    ),
    "1a72893d-ddef-4b62-a6a6-bb668f878a24": can(
      "enable_create_lead_activity_selfemployment",
    ),
    "01139c70-88d6-4dbc-8e5a-f725b5c23361": !can("skip_middle_name"),
    "e0c5d4de-775e-4257-9d8f-d7f9d5104913": !can("skip_birthday"),
    "d5869c0c-a437-49e3-bd9d-950de405481e": !can("skip_birthday"),
    "bfef206f-4359-4ca2-8b24-1859b7655269": !can(
      "skip_driver_license_country_of_issue",
    ),
    "d6d7d5ef-4e09-44eb-a965-2572a7cd53f8": !can(
      "skip_driver_license_country_of_issue",
    ),
    "a63a9aa0-a65d-49d1-857c-132e9f7c4694": !can(
      "skip_driver_license_country_of_issue",
    ),
    "cfc0c71d-0e46-48a0-8244-71019c5ee627": !can(
      "skip_driver_license_expiry_date",
    ),
    "a13547a3-b272-438e-bb76-ef12f292caa5": can(
      "select_park_for_natural_person",
    ),
    "b36d3185-3e9b-4c0b-be08-a1b90c95e0ea": can(
      "enable_create_lead_activity_lead",
    ),
    "92446de9-9f0a-4647-a4da-a01e8bbe5bff": can("show_invite_link"),
    "32f7b087-ced8-47fb-b9cc-79df1f631fbb": can("can_show_eats_lead_check"),
    "3482fb06-9802-4bd7-b231-a072ca5f47ce": can("can_create_users"),
    "2c7d3269-1647-439e-8195-26c17061293b": can(
      "redirect_to_ref_from_add_user_form",
    ),
    "588de70f-471e-4ad3-94ac-e78b1c3d9bfc": !can("skip_lead_check_step"),
    "89e65d8f-560e-42bc-adce-df1652cc8cb4": can("view_activation_list"),
    "8684f191-132f-4400-a524-3ea2de245003": can("view_activation_list"),
    "e5c8fe3c-2a26-4694-ad7d-15ad6028768c":
      can("view_generate_ref_link_page") ||
      can("redirect_to_ref_from_add_user_form"),
    "0c75c29b-7a63-417f-8694-d4e6579d2c4f": can("view_generate_ref_link_page"),
    "bd2615e5-aec4-42cc-b736-0c41ce334e83": can("show_park_selector"),
    "217af428-6ac4-480a-8669-7b8c898a1f87": can("show_flow_selector"),
    "59466288-f615-43e3-8dca-c53b20faa0bc": can("view_lead_complaints"),
    "dd0cb8fb-cf8f-4a0c-8afb-0968798f819e": can("view_lead_complaints"),
    "7f6ce2da-ec5f-4c6c-9e54-7e957f467412": can(
      "use_create_yango_driver_new_ui",
    ),
    "dd948d85-c33b-4198-ab19-fb5c79ecc861": can("view_gender_field"),
    "ed1dd768-a095-4824-8cca-80ae480bf9fd": !can("view_gender_field"),
    "c38d3280-fc1e-48b6-b101-601e7fa8e193": can(
      "enable_create_lead_activity_lead",
    ),
    "b12b6599-bba4-467b-bb19-96bfa0e345fb": can("view_vacancy_choose_page"),
    "ca99f2a5-1882-467e-80e8-cf1619bc6e01": can("view_vacancy_choose_page"),
    "b632bcec-f51d-4cba-b478-b014e8d317fe": can("view_vacancy_choose_page"),
    "195292d0-ac81-4673-92aa-beb5b432fbfb": can("view_teamlead_dashboard_page"),
    "e727c28a-9d00-4b4d-9fa6-c70e6ba15b86": can("view_teamlead_dashboard_page"),
    "8b0069bb-dbaf-479e-9a18-cfe17e9a0ad7": can(
      "view_download_csv_at_new_design",
    ),
    "9ce6ef50-bcc9-43dc-9ec8-0fdd7b6e6ffb": can("view_finances_page"),
    "5b1c62ec-32a2-4236-9aec-0f8c6c1f3faa": can("show_balance_at_dashboard"),
    "7e77025d-60d3-44a9-8aa6-36e50195648b": can("show_balance_at_dashboard"),
    "41b5afe4-7451-4221-8dc5-e71f890fcac6": can(
      "can_go_to_finances_page_from_balance_at_dashboard",
    ),
    "8527bf9a-8fdd-4a0e-9013-735bf407bbe0": can(
      "show_target_events_block_at_lead_page",
    ),
    "d8152a56-fb2f-45a2-bcc6-a121f9155652": can("enable_self_employment_usage"),
    "a9dd40bf-b359-4d81-9d0e-b09bc3668e76": can("enable_self_employment_usage"),
    "45ba232e-30a1-40d4-9c7e-04dccaaf597d": can("enable_self_employment_usage"),
    "909fb415-3596-453d-9a9c-8fbaf51e7608": can("enable_self_employment_usage"),
    "1aa0e4e0-8d1b-4640-9477-36438ed5233e": can("enable_self_employment_usage"),
    "2c5b1670-8f88-43b6-9633-673720b67c71": can("view_scout_page"),
    "7812d28b-d7ed-4f7a-87b2-86bb1f5f1098": can(
      "can_go_to_scout_page_from_teamlead_dashboard",
    ),
    "0409cbc7-c023-4c78-b7c3-2f93f3bf6425": can("can_change_park_for_ref_link"),
    "fb497050-e202-4803-87bf-692d56eab444": can("view_new_driver_check_form"),
    "d6b79418-f1c4-4805-8e68-e5c0887264ac": can("use_backend_detailed_lead"),
    "26b0c25d-ca35-453c-b8ae-464d3da69755":
      can("view_vacancy_choose_page") || can("view_create_lead"),
    "b20f9b63-509d-4049-b817-3b9b13b990a5": can("show_notification_center"),
  };

  return <Flags value={flags}>{children}</Flags>;
};
